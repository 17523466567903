import './services.scss';

const Services = () => {
  return (
    <div className='services' id="services">
      <div className='services__heading'><span>FACILITIES</span></div>
      <div className='services__boxes'>
          <div className='services__boxes__box services__boxes__box1'>
            <div className='center-the-div'><span>
            Spacious Lawn and Marriage Hall
            </span></div>
          </div>
          <div className='services__boxes__box services__boxes__box2'>
            <div className='center-the-div'><span>
            Customised Catering Services
            </span></div>
          </div>
          <div className='services__boxes__box services__boxes__box3'>
            <div className='center-the-div'><span>
            Capacity to Host 200 to 2000 Guests
            </span></div>
          </div>
          <div className='services__boxes__box services__boxes__box4'>
            <div className='center-the-div'><span>
            Parking Facility for up to 100 Cars
            </span></div>
          </div>
      </div>
    </div>
  )
}

export default Services
