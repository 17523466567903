import './footer.scss'
import logo from '../../../assets/images/logo_prev_ui.png'
const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__upper'>
        <div className='footer__upper__left'>
            <img src={logo} height={70} alt='logo'/>
        </div>
        <div className='footer__upper__right'>
            <div><span>Contact:</span> 91-9453464556</div>
            <div><span>Address:</span> We are located at 41/24, Nyaya Marg, Prayagraj, Uttar Pradesh, 211001</div>

        </div>
      </div>
      <div className='footer__lower'>
      ©2024 BHAGWATI GARDEN. ALL RIGHTS RESEVED.
      </div>
    </div>
  )
}

export default Footer
