import './contactUs.scss'
import mailIcon from '../../assets/images/mail.png';
import callIcon from '../../assets/images/phone-call.png';
import whatsapp from '../../assets/images/whatsapp.png';
import locationIcon from '../../assets/images/location.png';
const ContactUs = () => {
  return (
    <div className='contact-us' id="contact">
      <div className='contact-us__left'>
        <div className='contact-us__left__heading'>CONTACT US</div>
        <div className='contact-us__left__content'>

            <div className='contact-us__left__content__item'>
                <div className="contact-us__left__content__item__heading"><img alt='icons' src={mailIcon} /> <span>Email</span> </div>
                <div className='contact-us__left__content__item__text'>For inquiries, reach us at <span className='color-blue'>kapoorsangeeta100@gmail.com</span></div>
            </div>
            <div className='contact-us__left__content__item'>
                <div className="contact-us__left__content__item__heading"><img alt='icons' src={callIcon}/> <span>Phone</span></div>
                <div className='contact-us__left__content__item__text'>For immediate assistance, call us at <span className='color-black'>+91-9453464556</span></div>
            </div>
            <div className='contact-us__left__content__item'>
                <div className="contact-us__left__content__item__heading"><img alt='icons' src={whatsapp}/> <span>WhatsApp</span></div>
                <div className='contact-us__left__content__item__text'>Connect with us via WhatsApp at <span className='color-black'>+91-9453464556</span></div>
            </div>
            <div className='contact-us__left__content__item'>
                <div className="contact-us__left__content__item__heading"><img alt='icons' src={locationIcon}/> <span>Location</span></div>
                <div className='contact-us__left__content__item__text'>We are located at 41/24, Nyaya Marg, Prayagraj, Uttar Pradesh, 211001</div>
            </div>
        </div>
      </div>
      <div className='contact-us__right'>
        <div className='contact-us__right__content'>
          <a href='https://www.google.com/maps/dir//bhagwati+garden+google+map/@25.4580682,81.8192294,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x399acb87aac5a723:0xfa70c23803a41982!2m2!1d81.8239588!2d25.463179?entry=ttu&g_ep=EgoyMDI0MDkzMC4wIKXMDSoASAFQAw%3D%3D'
              target='_blank' rel="noopener noreferrer">
            Click here for google map location
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
