import './navbar.scss'
import logo from '../../../assets/images/png.jpg';
import menuIcon from "../../../assets/images/sidebar-icon.png";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const { hash } = useLocation();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] =
  useState<boolean>(false);

  return (
    <div className="navbar">
      <div className='navbar__left'>
        <div><img src={logo} height={56} alt='logo'/></div>
        <div className='alike-regular'>Bhagwati Garden</div>
      </div>
      <div
        className={`navbar__right  menu-toggle ${
          !isMobileSidebarOpen && "sm-disp-none"
        }`}
      >
          <div className="sidebar-top">
            <div
              className="close-sidebar"
              onClick={() => setIsMobileSidebarOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 50 50"
                overflow="visible"
                stroke="#fff"
                stroke-width="10"
                stroke-linecap="round"
              >
                <line x2="50" y2="50" />
                <line x1="50" y2="50" />
              </svg>
            </div>
          </div>
          <a href='#home' 
              className={`navbar__right__item ${hash === '#home' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                Home
          </a>
          <a href='#about' 
              className={`navbar__right__item ${hash === '#about' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                About
          </a>
          <a href='#services' 
              className={`navbar__right__item ${hash === '#services' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                Services
          </a>
          <a href='#contact' 
              className={`navbar__right__item ${hash === '#contact' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                Contact Us
          </a>
      </div>
      <div
        className="sidebar-menu-icon"
        onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
      >
        <img src={menuIcon} height={38} alt={menuIcon} />
      </div>
    </div>
  )
}

export default Navbar
